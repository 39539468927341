const firebaseConfig = {
  apiKey: "AIzaSyCO8m0m_SJLRnjjUZNBScsOO9wd0N3F4so",
  authDomain: "inhand-styles.firebaseapp.com",
  projectId: "inhand-styles",
  storageBucket: "inhand-styles.appspot.com",
  messagingSenderId: "433132513607",
  appId: "1:433132513607:web:7d8dc92fb446f6133d8462",
  measurementId: "G-KGZEQWXVMD"
};

export default firebaseConfig;