import React from 'react';

const Review = () => {
    return (
        <section style={{ backgroundColor: '#FDF5E6' }}  className='mt-5 py-5'>
            
        </section>
    );
};

export default Review;